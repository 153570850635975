<template>
  <div class="home">
    <div class="bg--main bg--main-fitness">
      <div class="container container--header">
        <div class="header">
          <router-link
            to="/"
            class="header__logo"
          />
          <div class="header-block">
            <p class="header-block__name">
              Добрый день!
            </p>
            <img
              src="@/assets/icons/name_icon.png"
              srcset="@/assets/icons/name_icon@2x.png 2x"
              alt=""
              class="header-block__icon"
            >
          </div>
        </div>
      </div>
      <div class="container">
        <div class="meditation">
          <h1 class="meditation__tit">
            Make up от звездного визажиста.
          </h1>
          <p class="meditation__text">
            2 урока преображения на косметике LETIQUE для разных типажей. Ты сможешь сама выбрать делать полный вечерний макияж или закончить на повседневном! Делимся секретами идеальных стрелок и роскошных ресниц, а также лайфхаками, которые будут незаменимыми в твоей бьюти рутине! Под каждым роликом ты найдешь названия средств, которые были в нем использованы, просто кликай на них, и переходи на страничку продукта.
          </p>
          <div class="meditation-blocks">
            <div v-for="(video, index) in videos" class="meditation-block meditation-block--fitness" :key="video.id">
              <a
                href="#"
                @click.prevent="selectTrack(video, index)"
              >
                <div class="meditation-block__img-block meditation-block__img-block--fitness">
                  <img
                    :src="video.image['2x'].replace('https://79f714d8-9b86-4071-b1da-ed403b2e9bbb.akamaized.net/', 'https://lerchek.fit/')"
                    alt="img"
                    class="meditation-block__img meditation-block__img--fitness"
                  >
                  <div class="meditation-block__info-play meditation-block__info-play--fitness" />
                </div>

              </a>
              <div class="meditation-block__info meditation-block__info--fitness">
                <div class="meditation-block__info-time">
                  {{ formatTime(video.duration) }}
                </div>
                <div class="meditation-block__info-name">
                  {{ video.name }}
                </div>
                <div
                  class="meditation-block__desc"
                  v-html="video.description.replace(/”/g, '').replace(/href/g, ' target=_blank href')"
                />
              </div>
            </div>
          </div>
          <div class="meditation__girl" />
        </div>
      </div>
    </div>
  
    <div
      v-if="video"
      class="player"
      @click.prevent="video = null"
    >
      <div
        class="player-block"
        @click.stop
      >
        <iframe
          allow="autoplay; fullscreen"
          allowfullscreen
          :src="video.url + '&autoplay=1'"
          class="vimeo"
          width="100%"
          height="100%"
        />
        <button
          class="player-block__close"
          @click.prevent="video = null"
        />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      videos: [],
      video: null,
      currentTrack: null,
    };
  },
  computed: {
    bg() {
      if (!this.video) {
        return null;
      }
      return this.video.image['2x'];
    },
    title() {
      if (process.env.VUE_APP_PROJECT_TITLE) {
        return process.env.VUE_APP_PROJECT_TITLE;
      }
      return '';
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const response = await this.$request.get('/api/guide/video', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.data.success) {
        this.$router.replace('/login');
      } else {
        this.videos = response.data.result.videos;
      }
    },
    selectTrack(video) {
      this.video = video;
    },
    formatTime(seconds) {
      let minutes = Math.floor(seconds / 60);
      let sec = Math.floor(seconds - minutes * 60);
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      sec = sec < 10 ? `0${sec}` : sec;
      return `${minutes}:${sec}`;
    },
  },
};
</script>
<style lang="scss">

.player-block__close {
  position: absolute;
  width: 3rem;
  height: 3rem;
  background: none;
  border: none;
  top: 0;
  &:before,
  &:after {
    content: ' ';
    width: 2px;
    background: #fff;
    height: 100%;
    transform: rotate(45deg);
    position: absolute;
  }
  &:after {
    transform: rotate(-45deg);
  }
  @media (max-width: 768px) {
    top: 0;
    right: 2rem;
  }
}
audio {
  display: none;
}
.meditation-block {
  &__img-block {
    overflow: hidden;
    & img {
      transition: .3s ease transform;
    }
  }
  &:hover {
    & img {
      transform: scale(1.1);
    }
    & .meditation-block__info-play:after {
      transform: translate(-50%, -50%) scale(0);
    }
  }
  &__info-play {
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      width: 8rem;
      height: 8rem;
      background: rgba(255,255,255,.2);
      border-radius: 50%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: .3s ease transform;
      @media (max-width: 768px) {
        width: 6rem;
        height: 6rem;
      }
    }
    &--fitness {
      position: absolute;
    }
  }
}
.meditation-block__img {
  @media (max-width: 768px) {
    top: -2rem;
  }
  &--fitness {
    top: 0;
  }
}
.player {
  display: flex;
  &-block {
    height: 81.7rem;
    width: 83.4rem;
    box-sizing: border-box;
    max-height: 90vh;
    padding: 3.7rem 3.7rem 4.3rem 3.7rem;
    @media (max-width: 768px) {
      min-height: 100vh;
      width: 100%;
      padding: 1.8rem 1.8rem 7rem;
      &__name {
        padding-top: 15rem;
      }
    }
    &__like {
      display: none;
    }
  }
}
</style>
